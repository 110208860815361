<template>
    <div class="container">
        <div class="large-12 medium-12 small-12 cell">
            <label>File
                <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
            </label>
            <button v-on:click="submitFile()">Отправить</button>
        </div>
    </div>
</template>

<script>
export default {
  props: ['model', 'context'],
  data () {
    return {
      file: '',
      localContext: this.context,
      local: this.model
    }
  },
  watch: {
    local () {
      this.model = this.local
      this.context = this.localContext
    },
    model () {
      this.local = this.model
      this.localContext = this.context
    }
  },
  methods: {
    submitFile () {
      let me = this
      let context = this.context
      let formData = new FormData()
      formData.append('file', this.file)
      this.$http.post(`${this.$config.api}/registryservice/files`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
        this.$http.post(`${this.$config.api}/registryservice/plugins/execute/ImportInvestmentExpensesBudgetToAdjustmentRequestAsyncCommand`,
          {
            'adjustmentRequestId': this.model,
            'fileId': response.data.id,
            'async': 'true'
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
          this.context.$msgbox.close()
          this.context.$msgbox({
            type: 'success',
            message: 'Загрузка шаблона поставлена в очередь. По завершении получите уведомление'
          })
        })
      })
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
    }
  }
}
</script>
